import logo from './rock_background.png';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="" alt="logo" />
        <p>

        </p>

      </header>
    </div>
  );
}

export default App;
